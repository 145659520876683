<template>
  <div>
    <div v-if="!isLoading">
      <div
        v-if="
          userQuotables.filter(
            (userQuotable) => userQuotable.saved_for_later === 0
          ).length !== 0
        "
      >
        <UserQuotablesQuoteTable
          v-if="storefrontStorefront.type === 'b2b'"
          :userQuotables="
            userQuotables.filter(
              (userQuotable) => userQuotable.saved_for_later === 0
            )
          "
          :isLoading="isLoading"
          :editable="editable"
        />
        <UserQuotablesRedeemTable
          v-if="storefrontStorefront.type === 'redeem'"
          :userQuotables="
            userQuotables.filter(
              (userQuotable) =>
                userQuotable.saved_for_later === 0 && userQuotable.quantity > 0
            )
          "
          :isLoading="isLoading"
          :editable="editable"
        />
      </div>
      <div v-else>No se han agregado productos al carrito</div>

      <div v-if="!hideSavedForLater">
        <div class="uk-text-large">Guardados para después</div>
        <div
          v-if="
            userQuotables.filter(
              (userQuotable) =>
                userQuotable.saved_for_later === 1 && userQuotable.quantity > 0
            ).length !== 0
          "
        >
          <UserQuotablesQuoteTable
            v-if="storefrontStorefront.type === 'b2b'"
            :userQuotables="
              userQuotables.filter(
                (userQuotable) => userQuotable.saved_for_later === 1
              )
            "
            :isLoading="isLoading"
            :editable="editable"
          />
          <UserQuotablesRedeemTable
            v-if="storefrontStorefront.type === 'redeem'"
            :userQuotables="
              userQuotables.filter(
                (userQuotable) => userQuotable.saved_for_later === 1
              )
            "
            :isLoading="isLoading"
            :editable="editable"
          />
        </div>
        <div v-else test="no-products-saved-for-later">
          No se han guardado productos para después
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="uk-text-center">
      <div uk-spinner></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserQuotablesRedeemTable from "@/views/Utils/UserQuotablesRedeemTable";
import UserQuotablesQuoteTable from "@/views/Utils/UserQuotablesQuoteTable";
export default {
  name: "UserQuotables",

  props: ["userQuotables", "isLoading", "editable", "hideSavedForLater"],

  components: {
    UserQuotablesRedeemTable,
    UserQuotablesQuoteTable,
  },

  computed: {
    ...mapGetters(["storefrontStorefront"]),
  },
};
</script>
